export default {
    en: {
        crud: {
            create: 'Create',
            update: 'Edit',
            delete: 'Delete',
        },
        titles: {
            create: 'Create {type}',
            update: 'Edit {type}: {name}',
            delete: 'Delete {type}: {name}',
        },
        errors: {
            index: 'Something went wrong while loading the {type} data.',
            create: 'Something went wrong while creating the {type}.',
            update: 'Something went wrong while editing the {type}.',
            delete: 'Something went wrong while deleting the {type}.',
        },
        working: {
            create: 'Creating...',
            update: 'Saving...',
            delete: 'Deleting...',
        },
        table: {
            loading: 'Loading {type} data...',
            error: 'Could not load {type} data.',
            none: 'No {type} data found.',
        },
        delete: 'Are you sure you want to delete this ${type}? This cannot be undone!',
    },
    nl: {
        crud: {
            create: 'Toevoegen',
            update: 'Bewerken',
            delete: 'Verwijderen',
        },
        titles: {
            table: '{type}',
            create: '{type} toevoegen',
            update: '{type} bewerken: {name}',
            delete: '{type} verwijderen: {name}',
        },
        errors: {
            index: 'Er ging iets mis bij het laden van de {type} data.',
            create: 'Er ging iets mis bij het toevoegen van de {type}.',
            update: 'Er ging iets mis bij het bewerken van de {type}.',
            delete: 'Er ging iets mis bij het verwijderen van de {type}.',
        },
        working: {
            create: 'Toevoegen...',
            update: 'Opslaan...',
            delete: 'Verwijderen...',
        },
        table: {
            loading: '{type} data laden...',
            error: 'Kon de {type} data niet laden.',
            none: 'Geen {type} data gevonden.',
        },
        delete: 'Weet je zeker dat je deze {type} wilt verwijderen? Dit kan niet ongedaan worden!',
    },
};
