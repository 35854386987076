require('./bootstrap');

import Vue from 'vue';
import Vuex from 'vuex';
import Notifications from 'vue-notification';
import CKEditor from 'ckeditor4-vue';
import BootstrapVue from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import ErrorHandler from 'vuejs-error-handler';
import ValueBuilder from './valueBuilder';

Vue.use(Vuex);
Vue.use(Notifications);
Vue.use(CKEditor);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(ErrorHandler);
Vue.use(ValueBuilder);

Vue.component('AboutRow', require('./components/AboutRow').default);
Vue.component('CompanyEditor', require('./components/job-board/CompanyEditor').default);
Vue.component('Contact', require('./components/contact/Contact').default);
Vue.component('EnrollStudent', require('./components/contact/EnrollStudent').default);
Vue.component('EnrollTeacher', require('./components/contact/EnrollTeacher').default);
Vue.component('EventEditor', require('./components/events/Editor').default);
Vue.component('FailedJobs', require('./components/jobs/FailedJobs').default);
Vue.component('LocationEditor', require('./components/job-board/LocationEditor').default);
Vue.component('MediaImage', require('./components/media/ImageEditor').default);
Vue.component('Modal', require('./components/Modal').default);
Vue.component('PageEditor', require('./components/pages/Editor').default);
Vue.component('Pagination', require('laravel-vue-pagination'));
Vue.component('Parallax', require('./components/Parallax').default);
Vue.component('Spinner', require('./components/Spinner').default);
Vue.component('SponsorEditor', require('./components/sponsors/Editor').default);
Vue.component('UpdateTypes', require('./components/pages/UpdateTypes').default);
Vue.component('UserTable', require('./components/UserTable').default);
Vue.component('VacancyEditor', require('./components/job-board/VacancyEditor').default);
Vue.component('VueInput', require('./components/VueInput').default);

new Vue({
    el: '#app',
    i18n: new VueI18n({
        locale: document.documentElement.lang.substr(0, 2),
        fallbackLocale: 'nl',
        enableInSFC: true,
        messages: require('./messages').default,
    }),
});

for (const [key, value] of Object.entries({
    particles: 'header',
})) {
    if (document.getElementById(key)) {
        particlesJS(key, require(`./particles/${value}`).default);
    }
}
