<template>
  <modal
    ref="modal"
    :title="$t('titles.create', {type})"
    :is-working="saving"
    :submit-text="$t('working.create')"
    submit-class="btn-success"
    @submit="save"
    @cancel="reset">
    <template #default>
      <fields-input
        :language="language"
        :disabled="saving"
        :rules="rules"
        :values="values"
        :errors="errors"
        @submit="save"
      />
    </template>

    <template #footer v-if="hasTranslations">
      <language-selector :languages="languages" v-model="language"/>
    </template>

    <template #submitLabel>
      <icon icon="plus"/>
      {{ $t('crud.create') }}
    </template>
  </modal>
</template>

<script>
import FieldsInput from '../input/FieldsInput';
import HasFieldRules from './HasFieldRules';
import HasTypeProperties from './HasTypeProperties';
import Icon from '../Icon';
import LanguageSelector from '../input/LanguageSelector';
import Modal from '../Modal';

export default {
    name: 'CreateModel',
    components: {FieldsInput, Icon, LanguageSelector, Modal},
    mixins: [HasFieldRules, HasTypeProperties],
    props: {
        url: {type: String, required: true},
        languages: {type: Array, required: true},
    },
    data: () => ({
        language: null,
        saving: false,
    }),
    methods: {
        save() {
            if (this.saving) {
                return null;
            }

            this.saving = true;

            return axios.post(this.url, this.saveData())
                .then(this.saveSuccess)
                .catch(this.saveFailed)
                .finally(this.saveFinally);
        },
        saveData() {
            return this.$valueBuilder.extractData(this.values);
        },
        saveSuccess({data: response}) {
            this.$emit('save', response.data);

            this.$refs.modal.close();
            this.reset();
        },
        saveFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.create', {type: this.type}),
                text: this.$errors.set(this.errors, error),
            });
        },
        saveFinally() {
            this.saving = false;
        },

        open() {
            this.$refs.modal.open();
        },
    },
    i18n: {
        messages: require('./ModelMessages').default,
    },
};
</script>
