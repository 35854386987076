<template>
  <div class="card">
    <div class="card-header d-flex align-items-baseline">
      <span class="card-title flex-grow-1" v-html="hasEvent ? 'Edit event' : 'Create event'"/>

      <a v-if="hasEvent && page" :href="page" target="_blank" class="ml-2">
        <fa icon="external-link-square-alt"/>
        Show calendar
      </a>
      <language-selector :languages="languages" v-model="language"/>
    </div>
    <div class="card-body">
      <language-input
          label="Title"
          :language="language"
          :error="errors.title_array"
          v-model="attributes.title_array"
      />
      <form-input
          label="Location"
          v-model="attributes.location"
          :error="errors.location"
      />
      <form-input
          label="Entrance cost"
          type="number"
          step=".01"
          v-model="attributes.entrance"
          :error="errors.entrance"
      />

      <hr/>

      <form-input
          label="Date"
          type="date"
          v-model="attributes.date"
          :error="errors.date"
      />
      <form-input
          label="Start time"
          type="time"
          v-model="attributes.start"
          :error="errors.start"
      />
      <form-input
          label="End time"
          type="time"
          v-model="attributes.end"
          :error="errors.end"
      />

      <hr/>

      <image-input
          label="Image"
          v-model="attributes.image"
          :error="errors.image"
          :languages="languages"
      />

      <language-input
          label="Content"
          :language="language"
          :html-editor="true"
          :error="errors.description_array"
          v-model="attributes.description_array"
      />
    </div>
    <div class="card-footer text-right">
      <delete v-if="hasEvent && urlDestroy"
              model="event"
              :url="urlDestroy"/>

      <button class="btn btn-outline-primary btn-sm"
              type="button"
              :disabled="loading"
              @click="submit">
        <span v-if="loading">
            <fa icon="circle-notch spinner"/>
            Saving
        </span>
        <span v-else>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
import LanguageSelector from '../input/LanguageSelector';
import LanguageInput from '../input/LanguageInput';
import ImageInput from '../input/ImageInput';
import FormInput from '../input/FormInput';
import Delete from '../input/Delete';
import Fa from '../fa';

export default {
    name: 'Editor',
    components: {Fa, Delete, FormInput, ImageInput, LanguageInput, LanguageSelector},
    props: {
        languages: {type: Array, required: true},
        url: {type: String, required: true},
        urlDestroy: {type: String, required: false},
        page: {type: String, required: false},
        event: {type: Object, required: false, default: null},
    },
    data() {
        return {
            attributes: this.getAttributes(true),
            errors: this.getAttributes(),
            loading: false,
            language: null,
        };
    },
    computed: {
        hasEvent: function () {
            return this.event !== null;
        },
    },
    methods: {
        getAttributes(defaults = false) {
            return {
                image: null, // default is null
                title_array: defaults ? [] : null,
                description_array: defaults ? [] : null,
                location: defaults ? '' : null,
                entrance: defaults ? '' : null,
                date: defaults ? '' : null,
                start: defaults ? '' : null,
                end: defaults ? '' : null,
            };
        },

        setData(data) {
            this.filterDate(data);
            this.attributes = data;
        },
        filterDate(data) {
            let input = data.date;

            if (!input) {
                return;
            }

            /*
             * Date::getDate() returns the day of the month (1-31)
             * Date::getMonth() returns the month of the year (0-11)
             *
             * Since month start at 0 we need to add one.
             * Both date and month are represented by integers and therefore will be displayed as just 1 or 2 etc.
             * Thus they need to be 'fixed'; converted to a string and a starting 0 if needed.
             */

            let date = new Date(input);
            data.date = `${date.getFullYear()}-${this.fixNumber(date.getMonth() + 1)}-${this.fixNumber(date.getDate())}`;
        },
        fixNumber(input) {
            return input < 10
                ? `0${input}`
                : `${input}`;
        },

        submit() {
            if (this.loading) {
                return;
            }

            this.submitStart(this.url, this.attributes)
            .then(this.submitSuccess)
            .catch(this.submitError)
            .finally(this.submitFinally);
        },
        submitStart(url, data) {
            this.loading = true;
            return this.hasEvent
                ? axios.put(url, data)
                : axios.post(url, data);
        },
        submitSuccess(response) {
            if (response.status === 201) {
                window.location = response.data.data.id;
                return;
            }

            this.setData(response.data.data);
            this.$errors.clear(this.errors);

            this.$notify('Event has been saved!');
        },
        submitError(error) {
            this.$notify({
                type: 'error',
                title: 'An error occurred while saving this event',
                text: this.$errors.set(this.errors, error),
            });
        },
        submitFinally() {
            this.loading = false;
        },
    },
    created() {
        if (this.hasEvent) {
            this.setData(this.event);
        }
    },
};
</script>
