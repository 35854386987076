<template>
    <div class="form-group row">
        <modal title="Select Image"
               ref="selector"
               submitClass="btn-warning"
               @submit="clear">
            <template slot=footer>
                <image-upload ref="upload" :languages="languages" @upload="getImages"></image-upload>
            </template>

            <template slot=submitLabel>
                Clear
            </template>

            <template>
                <div class="row img-row">
                    <div class="col-3" v-for="image in images" :key="image.id">
                        <img class="img-thumbnail"
                             :src="image.url"
                             :alt="image.alt"
                             @click="emitImage( image)"/>
                    </div>
                </div>
            </template>
        </modal>

        <label class="col-sm-4 col-form-label text-md-right"
               :for="inputId">
            <span v-html="label"/>
        </label>
        <div class="col-md-6">
            <div class="input-group">
                <div class="form-control d-flex h-auto"
                     :class="{'is-invalid': error}">
                    <img v-if="value"
                         class="img-fluid img-selected mx-auto"
                         :src="`/image/${value}`"
                         alt="Selected image"/>
                    <span v-else class="text-muted">Select an image</span>
                </div>
                <div class="input-group-append">
                    <button class="btn btn-outline-success rounded-right"
                            type="button"
                            @click="openSelector"
                            :id="inputId">
                        Select
                    </button>
                </div>

                <form-labels :error="error" :help="help"/>
            </div>
        </div>
    </div>
</template>

<script>
import FormLabels from './FormLabels';
import ImageUpload from './ImageUpload';

export default {
    name: 'ImageInput',
    components: {FormLabels, ImageUpload},
    props: {
        label: {type: String, required: true},
        error: {type: String, required: false},
        help: {type: String, required: false},
        value: {type: Number, required: false, default: null},
        languages: {type: Array, required: false},
    },
    data: () => ({
        loading: false,
        selectedImage: [],
        images: [],
    }),
    computed: {
        inputId() {
            return `${this._uid}-input`;
        },
    },
    methods: {
        getImages() {
            if (this.loading) return;

            return this.getImagesStart()
                .then(this.getImagesSuccess)
                .catch(this.getImagesError)
                .finally(this.getImagesFinally);
        },
        getImagesStart() {
            this.loading = true;
            return axios.get('/admin/media/inventory');
        },
        getImagesSuccess({data}) {
            this.images = data.data;
        },
        getImagesError(error) {
            this.$notify({
                type: 'error',
                title: 'An error occurred while fetching images',
                text: this.$errors.set([], error),
            });
        },
        getImagesFinally() {
            this.loading = false;
        },

        openUpload() {
            this.$refs.upload.openUpload();
        },

        openSelector() {
            this.$refs.selector.open();
        },
        clear() {
            this.$emit('input', null);
            this.$refs.selector.close();
        },
        emitImage({id}) {
            this.$emit('input', id);
            this.$refs.selector.close();
        },
    },
    created() {
        this.getImages();
    },
};
</script>

<style scoped lang="scss">
.img- {
  &thumbnail {
    margin-bottom: 30px;
  }

  &row {
    margin-bottom: -30px;
  }

  &selected {
    max-height: 200px;
  }
}
</style>
