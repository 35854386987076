<script>
import Enrollment from './Enrollment';
import teacherRules from './rules/teacher';

export default {
    name: 'EnrollTeacher',
    extends: Enrollment,
    methods: {
        fieldRules: () => teacherRules,
    },
    i18n: {
        messages: {
            en: {team: 'Team',},
            nl: {team: 'Team',},
        },
        sharedMessages: require('./messages').default,
    },
};
</script>
