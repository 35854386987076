<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <div class="form-group row">
    <template v-if="type === 'checkbox'">
      <div class="offset-lg-3 col-lg-9">
        <div class="custom-control" :class="toggle ? 'custom-switch' : 'custom-checkbox'">
          <input
            class="custom-control-input"
            :id="labelId"
            :name="name"
            :disabled="disabled"
            type="checkbox"
            :required="required"/>

          <label :for="labelId" class="custom-control-label" v-text="label"/>
          <span v-if="required" class="text-danger">*</span>
        </div>

        <small v-if="help" class="form-text text-muted" v-text="help"/>
      </div>
    </template>

    <template v-else-if="type === 'translation'">
      <div class="col-form-label" :class="{'col-lg-3 text-lg-right': !html, 'col-12': html}">
        <icon icon="language"/>
        <span class="bg-dark text-light text-uppercase rounded-circle p-2" v-text="language"/>
        <label :for="labelId" class="mb-0" v-text="label"/>
        <span v-if="required" class="text-danger">*</span>
      </div>

      <div :class="{'col-lg-9': !html, 'col-12': html}">
        <ckeditor
          v-if="html"
          class="rounded"
          :class="{'is-invalid border border-danger': error}"
          :id="labelId"
          :key="language"
          v-model="value[language]"
        />

        <div v-else class="input-group" :class="{'is-invalid rounded-right' : error}">
          <input
            class="form-control"
            :class="{'is-invalid' : error}"
            :id="labelId"
            :name="name"
            :key="language"
            @keyup.enter="$emit('submit')"
            :autofocus="autofocus"
            :required="required"
            :autocomplete="autocomplete"
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="value[language]"
          >
        </div>

        <span v-if="error" class="invalid-feedback" role="alert">
            <strong v-text="error"/>
          </span>
        <small v-if="help" class="form-text text-muted" v-text="help"/>
      </div>
    </template>

    <template v-else>
      <div class="col-form-label col-lg-3 text-lg-right">
        <label :for="labelId" class="mb-0" v-text="label"/>
        <span v-if="required" class="text-danger">*</span>
      </div>

      <div class="col-lg-9">
        <div class="input-group">
          <select v-if="type === 'select'"
                  class="custom-select"
                  :class="{'is-invalid rounded-right' : error}"
                  :disabled="disabled"
                  :id="labelId"
                  :value="value"
                  @change="$emit('input', $event.target.value)">
            <empty-option :selected="!value"/>

            <option
              v-for="option in options"
              :value="option.value"
              :selected="option.value === value"
              v-text="option.title"/>
          </select>

          <div v-else-if="type === 'radio'" class="form-control h-auto" :class="{'is-invalid rounded-right' : error}">
            <div v-for="option in options" class="custom-control custom-radio custom-control-inline">
              <input
                class="custom-control-input"
                type="radio"
                :disabled="disabled"
                :id="optionId(option)"
                :name="name"
                :value="option.value"
                :checked="option.value === value"
                @click="$emit('input', option.value)"/>

              <label
                class="custom-control-label"
                :for="optionId(option)"
                v-text="option.title"/>
            </div>
          </div>

          <textarea
            v-else-if="type === 'textarea'"
            class="form-control"
            :class="{'is-invalid rounded-right' : error}"
            :id="labelId"
            :name="name"
            :disabled="disabled"
            rows="10"
            :required="required"
            @input="$emit('input', $event.target.value)">
            {{ value }}
          </textarea>

          <input
            v-else-if="type === 'image'"
            class="form-control"
            :id="labelId"
            :name="name"
            type="file"
            accept="image/jpeg,image/png"
            @input="$emit('input', $event.target.files[0])"
          >

          <input
            v-else
            class="form-control"
            :class="{'is-invalid rounded-right': error}"
            :id="labelId"
            :name="name"
            :type="type"
            :value="value"
            :pattern="pattern"
            :autofocus="autofocus"
            :required="required"
            :autocomplete="autocomplete"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="$emit('input', $event.target.value)"
            @keyup.enter="$emit('submit')"
          >

          <span v-if="error" class="invalid-feedback" role="alert">
            <strong v-text="error"/>
          </span>
        </div>

        <small v-if="help" class="form-text text-muted" v-text="help"/>
      </div>
    </template>
  </div>
</template>

<script>
import EmptyOption from './input/EmptyOption';
import Icon from './Icon';

export default {
    name: 'VueInput',
    components: {EmptyOption, Icon},
    props: {
        // Default values
        name: {type: String, required: true},
        type: {type: String, required: false, default: 'text'},
        label: {type: String, required: true},
        help: {type: String, required: false, default: undefined},
        error: {type: String, required: false, default: undefined},
        placeholder: {type: String, required: false, default: undefined},
        toggle: {type: Boolean, required: false, default: false},
        pattern: {type: String, required: false, default: undefined},
        autocomplete: {type: Boolean, required: false, default: false},
        autofocus: {type: Boolean, required: false, default: false},
        required: {type: Boolean, required: false, default: false},
        options: {type: Array, required: false, default: () => []},
        disabled: {type: Boolean, required: false, default: false},
        value: {type: [String, Number, Array, Object], required: false, default: undefined},
        html: {type: Boolean, required: false, default: false},
        language: {type: String, required: false, default: 'nl'},
    },
    computed: {
        labelId() {
            return `input-${this._uid}`;
        },
    },
    methods: {
        optionId(option) {
            return `option-${option.value}-${this._uid}`;
        },
    },
};
</script>

<style scoped>
*:not(.form-row) > div.form-group:last-child {
  margin-bottom: 0;
}
</style>
