const {rules: nameRules} = require('./name').default;
const {rules: sharedRules} = require('./shared').default;

export default {
    rules: (wrapper) => ({
        ...nameRules(wrapper),
        ...sharedRules(wrapper),
        student_number: {label: wrapper.$t('nr'), pattern: '[sS]?\\d{7}', required: true},
        // student_card: {
        //     label: wrapper.$t('card'),
        //     type: 'image',
        //     required: true,
        // },
        study_field: {
            label: wrapper.$t('study.field'),
            type: 'select',
            options: [
                {value: 'p', title: wrapper.$t('study.field.p')},
                {value: 'esa', title: wrapper.$t('study.field.esa')},
                {value: 'se', title: wrapper.$t('study.field.se')},
                {value: 'bim', title: wrapper.$t('study.field.bim')},
                {value: 'ids', title: wrapper.$t('study.field.ids')},
            ],
            required: true,
        },
        study_type: {
            label: wrapper.$t('study.type'),
            type: 'radio',
            options: [
                {value: 'full', title: wrapper.$t('study.type.full')},
                {value: 'part', title: wrapper.$t('study.type.part')},
                {value: 'minor', title: wrapper.$t('study.type.minor')},
            ],
            required: true,
        },
        minor: {
            label: wrapper.$t('study.minor'),
            enabled: () => wrapper.values.study_type === 'minor',
            required: true,
        },
        cohort: {
            label: wrapper.$t('cohort.title'),
            help: wrapper.$t('cohort.explain'),
            pattern: '\\d{4,}',
            required: true,
        },
        birth_date: {
            label: wrapper.$t('date'),
            type: 'date',
            required: true,
        },
    }),
};
