<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <div v-if="htmlEditor" class="form-group row">
    <div class="col-xl-8 mx-auto">
      <div class="row">
        <label class="col col-form-label">
          <fa icon="language"/>
          <span v-html="label"/>
        </label>
      </div>
      <div class="row">
        <div class="col">
          <ckeditor v-model="translation" :id="inputId" :key="language"/>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="form-group row">
    <label class="col-sm-4 col-form-label text-md-right"
           :for="inputId">
      <fa icon="language"/>
      <span v-html="label"/>
    </label>
    <div class="col-md-6">
      <div class="input-group">
        <input class="form-control"
               :class="{'is-invalid rounded-right' : error}"
               :id="inputId"
               v-model="translation"/>
        <form-labels :error="error" :help="help"/>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from './LanguageSelector';
import FormLabels from './FormLabels';
import Fa from '../fa';

export default {
    name: 'LanguageInput',
    components: {Fa, FormLabels, LanguageSelector},
    props: {
        label: {type: String, required: true},
        htmlEditor: {type: Boolean, default: false},
        language: {type: String, required: false},
        value: {type: Array | Object, required: false, default: () => []},
        error: {type: String, required: false},
        help: {type: String, required: false},
    },
    data() {
        return {
            translation: '',
        };
    },
    computed: {
        inputId() {
            return `${this._uid}-input`;
        },
    },
    watch: {
        language(language) {
            if (!language) {
                return;
            }

            if (this.value[language]) {
                this.translation = this.value[language];
            } else {
                this.translation = '';
            }
        },
        translation(value) {
            if (!this.language) {
                return;
            }

            let translations = Array.isArray(this.value)
                ? {}
                : this.value;

            translations[this.language] = value ?? '';
            this.$emit('input', translations);
        },
    },
    created() {
        if (!this.language) {
            return;
        }

        this.translation = this.value[this.language] ?? '';
    },
};
</script>

<style scoped>
.form-group.row:last-child {
    margin-bottom: 0 !important;
}
</style>
