const {rules: nameRules} = require('./name').default;
const {rules: sharedRules} = require('./shared').default;

export default {
    rules: (wrapper) => ({
        ...nameRules(wrapper),
        ...sharedRules(wrapper),
        birth_date: {label: wrapper.$t('date'), type: 'date'},
        team: {label: wrapper.$t('team')},
    }),
};
