<template>
  <div class="d-inline" ref="container">
    <modal title="Delete"
           submitText="Deleting"
           ref="modal"
           modal-class="text-left"
           submit-class="btn-danger"
           :is-working="loading"
           @submit="destroy">
      <template v-slot:submitLabel>
        <i class="fas fa-minus-circle"></i>&nbsp;Delete
      </template>
      <template>
        Are you sure you want to delete this {{ model }}? This action cannot be undone!
      </template>
    </modal>

    <button class="btn btn-outline-danger btn-sm"
            type="button"
            @click="destroyOpen">
      Delete
    </button>
  </div>
</template>

<script>
export default {
    name: 'Delete',
    props: {
        model: {type: String, required: true},
        url: {type: String, required: true},
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        destroyOpen() {
            this.$refs.modal.open();
        },
        destroy() {
            if (this.loading) {
                return false;
            }

            this.destroyStart()
            .then(this.destroySuccess)
            .catch(this.destroyError)
            .finally(this.destroyFinally);
        },
        destroyStart() {
            this.loading = true;
            return axios.delete(this.url);
        },
        destroySuccess() {
            window.location = '..';
        },
        destroyError(error) {
            this.$notify({
                type: 'error',
                title: `An error occurred while deleting this ${this.model}`,
                text: this.$errors.set([], error),
            });
        },
        destroyFinally() {
            this.loading = false;
        },
    },
};
</script>
