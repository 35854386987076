<template>
  <select class="custom-select"
          :id="selectId"
          v-bind:value="value"
          v-on:input="emitValue">
    <empty-option/>
    <option v-for="option in options"
            :value="option.value"
            v-html="option.text"/>
  </select>
</template>

<script>
import EmptyOption from './EmptyOption';

export default {
    name: 'VueSelect',
    components: {EmptyOption},
    props: {
        options: {type: Array, required: true},
        value: null,
    },
    computed: {
        selectId: function () {
            return this._uid + '-select';
        },
    },
    methods: {
        emitValue(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
