<template>
    <div style="width: 100%">
        <button
            type="button"
            class="btn btn-success"
            @click="isActive = !isActive"
        >
            Add image
        </button>

        <div v-if="isActive" class="card mt-2">
            <div class="card-header d-flex align-items-baseline">
                <span class="card-title flex-grow-1">Upload image</span>

                <language-selector :languages="languages" v-model="language"/>
            </div>

            <div class="card-body">
                <language-input
                    label="Name"
                    :language="language"
                    :error="errors.alt_array"
                    v-model="attributes.alt_array"
                />

                <form-input
                    label="Image"
                    type="file"
                    accept="image/png"
                    :error="errors.file"
                    v-model="attributes.file"
                />
            </div>

            <div class="card-footer text-right">
                <button class="btn btn-outline-primary btn-sm"
                        type="button"
                        :disabled="loading"
                        @click="submit">
                <span v-if="loading">
                    <fa icon="circle-notch spinner"/>
                    Uploading
                </span>
                    <span v-else>Upload</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LanguageSelector from '../input/LanguageSelector';
import LanguageInput from '../input/LanguageInput';
import FormInput from '../input/FormInput';

export default {
    name: 'ImageUpload',
    components: {LanguageSelector, LanguageInput, FormInput},

    props: {
      languages: { type: Array, required: true }
    },

    data() {
        return {
            isActive: false,
            attributes: this.getAttributes(true),
            language: null,
            errors: this.getAttributes(),
            loading: false,
        };
    },

    computed: {
        hasImage: function () {
            return this.image !== null;
        },
    },

    methods: {
        getAttributes(defaults = false) {
            return {
                alt_array: defaults ? [] : null,
                file: defaults ? '' : null,
            };
        },

        convertData() {
            let data = new FormData;
            data.append('file', this.attributes.file);

            for (let language of this.languages) {
                if (!this.attributes.alt_array.hasOwnProperty(language.value)) {
                    continue;
                }

                data.append(
                    `alt_array[${language.value}]`,
                    this.attributes.alt_array[language.value],
                );
            }

            return data;
        },

        submit() {
            if (this.loading) {
                return;
            }

            this.submitStart("/admin/media/images", this.convertData())
                .catch(this.submitError)
                .finally(this.submitFinally);
        },
        submitStart(url, data) {
            this.loading = true;
            return axios.post(url, data);
        },
        submitError(error) {
            this.$notify({
                type: 'error',
                title: 'An error occurred while uploading this image',
                text: this.$errors.set(this.errors, error),
            });
        },
        submitFinally() {
            this.loading = false;
            this.$emit('upload');
            this.isActive = false;
        },
    }
};
</script>

<style scoped lang="scss">
.img- {
  &thumbnail {
    margin-bottom: 30px;
  }

  &row {
    margin-bottom: -30px;
  }

  &selected {
    max-height: 200px;
  }
}
</style>
