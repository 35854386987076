<template>
  <model-editor
    url="/api/v1/job-board/vacancies"
    :title="$t('title')"
    :type="$t('type')"
    :fields="rules"
    :columns="[
        {key: 'company_id', label: $t('field.company')},
        {key: 'location_id', label: $t('field.location')},
        {key: 'employment', label: $t('field.employment')},
        {key: 'field_of_study', label: $t('field.field')},
    ]"
    :languages="languages">
    <template #data_company_id="{item: vacancy}">
      {{ label(companies, vacancy.company_id) }}
    </template>

    <template #data_location_id="{item: vacancy}">
      {{ label(locations, vacancy.location_id) }}
    </template>

    <template #data_employment="{item: vacancy}">
      {{ label(employments, vacancy.employment) }}
    </template>

    <template #data_field_of_study="{item: vacancy}">
      {{ label(fields, vacancy.field_of_study) }}
    </template>
  </model-editor>
</template>

<script>
import ModelEditor from '../model/ModelEditor';
import VacancyData from './VacancyData';

export default {
    name: 'VacancyEditor',
    components: {ModelEditor},
    mixins: [VacancyData],
    props: {
        languages: {type: Array, required: true},
        companyId: {type: Number, required: false},
    },
    methods: {
        label(items, id) {
            const applicable = items.filter((item) => item.value === id);

            return applicable.length > 0
                ? applicable[0].title
                : `${id}`;
        },
    },
    i18n: {
        messages: {
            en: {
                title: 'Vacancies',
                type: 'Vacancy',
                field: {
                    title: 'Title',
                    content: 'Description',
                    company: 'Company',
                    location: 'Location',
                    employment: 'Employment',
                    field: 'Field of Study',
                },
            },
            nl: {
                title: 'Vacatures',
                type: 'Vacature',
                field: {
                    title: 'Titel',
                    content: 'Omschrijving',
                    company: 'Bedrijf',
                    location: 'Locatie',
                    employment: 'Dienstverband',
                    field: 'Studierichting',
                },
            },
        },
    },
};
</script>
