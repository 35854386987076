<template>
  <modal
    ref="modal"
    :title="$t('titles.delete', {type, name: item.name})"
    :is-working="deleting"
    :submit-text="$t('working.delete')"
    submit-class="btn-danger"
    @submit="deleteItem"
    @cancel="reset">
    <template #default>
      {{ $t('delete', {type}) }}
    </template>

    <template #submitLabel>
      <icon icon="trash-alt"/>
      {{ $t('crud.delete') }}
    </template>
  </modal>
</template>

<script>
import HasTypeProperties from './HasTypeProperties';
import Icon from '../Icon';
import Modal from '../Modal';

export default {
    name: 'DeleteModel',
    components: {Icon, Modal},
    mixins: [HasTypeProperties],
    props: {
        deleteUrl: {type: Function, required: true},
    },
    data: () => ({
        deleting: false,
        item: {},
    }),
    methods: {
        deleteItem() {
            if (this.deleting) {
                return null;
            }

            this.deleting = true;

            return axios.delete(this.deleteUrl(this.item))
                .then(this.deleteSuccess)
                .catch(this.deleteFailed)
                .finally(this.deleteFinally);
        },
        deleteSuccess() {
            this.$emit('delete');

            this.$refs.modal.close();
            this.reset();
        },
        deleteFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.delete', {type: this.type}),
                text: this.$errors.set([], error),
            });
        },
        deleteFinally() {
            this.deleting = false;
        },

        open(item) {
            this.item = item;
            this.$refs.modal.open();
        },
        reset() {
            setTimeout(() => (this.item = {}), 500);
        },
    },
    i18n: {
        messages: require('./ModelMessages').default,
    },
};
</script>
