export default {
    name: 'VacancyData',
    data: () => ({
        loading: {
            editor: false,
            companies: false,
            locations: false,
            employments: false,
            fields: false,
        },
        rules: {},
        companies: [],
        locations: [],
        employments: [],
        fields: [],
    }),
    created() {
        return this.loadData();
    },
    methods: {
        loadData() {
            if (this.loading.editor) {
                return null;
            }

            this.loading.editor = true

            return this.loadDataStart()
                .then(this.loadDataSuccess)
                .catch(this.loadDataFailed)
                .finally(this.loadDataFinished);
        },
        loadDataStart() {
            return Promise.all([
                this.loadCompanies(),
                this.loadLocations(),
                this.loadEmployments(),
                this.loadFields(),
            ]);
        },
        loadDataSuccess() {
            this.rules = require('./rules/VacancyRules')
                .default
                .rules(this);
        },
        loadDataFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.main'),
                text: this.$errors.set([], error),
            });
        },
        loadDataFinished() {
            this.loading.editor = false;
        },

        loadCompanies() {
            if (this.loading.companies) {
                return null;
            }

            this.loading.companies = true;

            return this.loadCompaniesStart()
                .catch(this.loadCompaniesFailed)
                .finally(this.loadCompaniesFinished)
        },
        loadCompaniesStart(page = 1) {
            return axios.get('/api/v1/job-board/companies', {params: {page}})
                .then(this.loadCompaniesSuccess);
        },
        loadCompaniesSuccess({data: response}) {
            const companies = this.mapModel(response.data);
            const meta = response.meta;

            this.companies = meta.current_page === 1
                ? companies
                : this.companies.concat(companies);

            return meta.current_page < meta.last_page
                ? this.loadCompaniesStart(meta.current_page + 1)
                : null;
        },
        loadCompaniesFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.companies'),
                text: this.$errors.set([], error),
            });
        },
        loadCompaniesFinished() {
            this.loading.companies = false;
        },

        loadLocations() {
            if (this.loading.locations) {
                return null;
            }

            this.loading.locations = true;

            return this.loadLocationsStart()
                .catch(this.loadLocationsFailed)
                .finally(this.loadLocationsFinished);
        },
        loadLocationsStart(page = 1) {
            return axios.get('/api/v1/job-board/locations', {params: {page}})
                .then(this.loadLocationsSuccess);
        },
        loadLocationsSuccess({data: response}) {
            const locations = this.mapModel(response.data);
            const meta = response.meta;

            this.locations = meta.current_page === 1
                ? locations
                : this.locations.concat(locations);

            return meta.current_page < meta.last_page
                ? this.loadLocationsStart(meta.current_page + 1)
                : null;
        },
        loadLocationsFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.locations'),
                text: this.$errors.set([], error),
            });
        },
        loadLocationsFinished() {
            this.loading.locations = false;
        },

        loadEmployments() {
            if (this.loading.employments) {
                return null;
            }

            this.loading.employments = true;

            return axios.get('/api/v1/job-board/employments')
                .then(this.loadEmploymentsSuccess)
                .catch(this.loadEmploymentsFailed)
                .finally(this.loadEmploymentsFinished);
        },
        loadEmploymentsSuccess({data: response}) {
            this.employments = this.mapEnum(response.data);
        },
        loadEmploymentsFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.employment'),
                text: this.$errors.set([], error),
            });
        },
        loadEmploymentsFinished() {
            this.loading.employments = false;
        },

        loadFields() {
            if (this.loading.fields) {
                return null;
            }

            this.loading.fields = true;

            return axios.get('/api/v1/job-board/fields')
                .then(this.loadFieldsSuccess)
                .catch(this.loadFieldsFailed)
                .finally(this.loadFieldsFinished);
        },
        loadFieldsSuccess({data: response}) {
            this.fields = this.mapEnum(response.data);
        },
        loadFieldsFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.field'),
                text: this.$errors.set([], error),
            });
        },
        loadFieldsFinished() {
            this.loading.fields = false;
        },

        mapModel(data) {
            return Object.keys(data).reduce((map, key) => {
                map.push({
                    title: data[key].name,
                    value: data[key].id,
                });

                return map;
            }, []);
        },
        mapEnum(data) {
            return Object.keys(data).reduce((map, key) => {
                map.push({
                    title: data[key].name,
                    value: data[key].key,
                });

                return map;
            }, []);
        },
    },
};
