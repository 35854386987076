<template>
  <i :class="`fas fa-${icon}`"/>
</template>

<script>
export default {
    name: 'fa',
    props: {
        icon: {type: String, required: true},
    },
};
</script>
