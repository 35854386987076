<template>
  <table class="table table-hover table-striped table-responsive">
    <thead class="thead-dark">
    <tr>
      <th scope="col">Connection</th>
      <th scope="col">Queue</th>
      <th scope="col">Payload</th>
      <th scope="col">Exception</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="job in jobs">
      <th scope="row">{{ job.connection }}</th>
      <td>{{ job.queue }}</td>
      <td v-html="decodePayload(job.payload)"/>
      <td>{{ job.exception.split(':')[0] }}</td>
      <td>
        <job-buttons :job="job"/>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<!--suppress JSUnfilteredForInLoop -->
<script>
import JobButtons from './JobButtons';

export default {
    name: 'FailedJobs',
    components: {JobButtons},
    data() {
        return {
            jobs: {},
        };
    },
    methods: {
        requeue(id) {
            axios.post(`/admin/jobs/${id}/requeue`);
        },
        reload() {
            axios.get('/admin/jobs/list')
            .then(response => this.jobs = response.data.data);
        },

        decodePayload(payload) {
            return this.decodePayloadList(JSON.parse(payload));
        },
        decodePayloadList(object) {
            return `<ul class="job-list">${this.decodePayloadObject(object)}</ul>`;
        },
        decodePayloadObject(object) {
            let html = '';
            for (let key in object) {
                let attr = object[key];
                html += attr instanceof Object
                    ? this.decodePayloadList(attr)
                    : `<li>${key}: ${attr}</li>`;
            }
            return html;
        },
    },
    created() {
        this.reload();
        setInterval(this.reload, 5000);
    },
};
</script>

<style lang="scss">
ul.job-list:first-child {
  padding-left: 0 !important;
}
</style>
