<template>
  <table class="table bg-light" :class="{'table-striped table-hover': !loading}">
    <thead class="thead-dark">
    <tr>
      <th v-text="$t('table.name')"/>
      <th v-text="$t('table.email')"/>
      <th v-text="$t('table.permissions')"/>
      <th/>
    </tr>
    </thead>

    <tbody>
    <tr v-if="loading">
      <td class="text-center" colspan="4">
        <load-icon :loading="loading"/>
      </td>
    </tr>

    <tr v-else v-for="user in results.data">
      <td v-text="user.name"/>
      <td>
        <span v-text="user.email"/>

        <div v-if="user.email_verified_at !== null">
          <span class="badge badge-pill badge-success"><i class="fas fa-thumbs-up"/></span>
          <span v-text="$t('email.verified')"/>
        </div>

        <div v-else>
          <span class="badge badge-pill badge-danger"><i class="fas fa-thumbs-down"/></span>
          <span v-text="$t('email.not_verified')"/>
        </div>
      </td>

      <td>
        <span v-if="user.scopes.length === 0" v-text="$t('scopes.none')"/>
        <span v-else-if="user.scopes.includes('*')" v-text="$t('scopes.super')"/>

        <span v-else v-for="scope in user.scopes" v-text="scope"/>
      </td>

      <td class="text-right align-middle">
        <button class="btn btn-primary" @click="save(user)"><i class="fas fa-save">&nbsp;</i>{{ $t('save') }}</button>
      </td>
    </tr>
    </tbody>

    <tfoot v-if="!loading">
    <tr>
      <td colspan="4">
        <pagination
          :align="'center'"
          :data="results"
          :limit=1
          @pagination-change-page="getResults"/>
      </td>
    </tr>
    </tfoot>
  </table>
</template>

<script>
import LoadIcon from './Spinner';

export default {
    name: 'UserTable',
    components: {LoadIcon},
    props: {
        url: {type: String, required: true},
    },
    data: () => ({
        results: {},
        loading: false,
    }),
    created() {
        return this.getResults();
    },
    methods: {
        start() {
            if (this.loading === true || this.url === null) {
                return false;
            }

            return (this.loading = true);
        },
        stop() {
            this.loading = false;
        },

        getResults(page = 1) {
            if (!this.start()) {
                return;
            }

            return axios.get(this.url, {params: {page}})
                .then(response => this.results = response.data)
                .catch(console.log)
                .finally(this.stop);
        },
        save(user) {
            axios.put(`/api/v1/users/${user.id}`, {
                email:user.email
            });
        }
    },
    i18n: {
        messages: {
            en: {
                scopes: {
                    none: 'No permissions',
                    super: 'Superuser',
                },
                table: {
                    name: 'Name',
                    email: 'Email',
                    permissions: 'Permissions',
                },
                email: {
                    verified: 'verified',
                    not_verified: 'not verified',
                },
                save: 'Save',
            },
            nl: {
                scopes: {
                    none: 'Geen rechten',
                    super: 'Superuser',
                },
                table: {
                    name: 'Naam',
                    email: 'E-mail',
                    permissions: 'Rechten',
                },
                email: {
                    verified: 'geverifieerd',
                    not_verified: 'niet geverifieerd',
                },
                save: 'Opslaan',
            },
        },
    },
};
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
ul.pagination {
  margin-bottom: 0 !important;
}
</style>
