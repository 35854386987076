<template>
  <section class="row pb-3">
    <div class="col-lg-3 col-md-4 p-0">
      <a v-if="link" :href="link" :alt="title">
        <img class="rounded-circle"
             :src="image"
             :alt="image_alt"
             data-aos="fade-up"/>
      </a>
      <img v-else class="rounded-circle"
           :src="image"
           :alt="image_alt"
           data-aos="fade-up"/>
    </div>
    <div class="p-3 col-lg-9 col-md-8">
      <div class="about-head">
        <h3 v-html="title"/>
        <a v-if="link" :href="link" :alt="title">
          {{ $t('pages.sponsor.show', {title: title}) }}
        </a>
        <a v-if="email"
           v-html="email"
           :href="`mailto:${email}`"/>
      </div>
      <slot/>
    </div>
  </section>
</template>

<script>
export default {
    name: 'AboutRow',
    props: {
        title: {type: String, required: true},
        email: {type: String, required: false},
        link: {type: String, required: false},
        image: {type: String, required: true},
        image_alt: {type: String, required: true},
    },
};
</script>

<style scoped lang="scss">
section {
  word-wrap: break-word;
  display: flex;

  img {
    position: -webkit-sticky;
    position: sticky;
    max-width: 100%;
    height: auto;
    width: 100%;
    top: 5px;
  }
}

@media (min-width: 768px) {
  section {
    &:nth-child(odd) {
      > :first-child {
        order: 1 !important;
      }

      > :last-child {
        order: 0 !important;
      }

      .about-head, p {
        text-align: right;
      }
    }

    &:nth-child(even) {
      > :first-child {
        order: 0 !important;
      }

      > :last-child {
        order: 1 !important;
      }

      .about-head, p {
        text-align: left;
      }
    }
  }
}

@media (max-width: 767px) {
  section p {
    text-align: justify !important;
  }
}
</style>
