<template>
  <div v-if="Object.keys(attributes).length > 0">
    <hr/>

    <div v-for="attribute of attributes" class="attribute-row">
      <language-input
          v-if="isTranslation(attribute)"
          :htmlEditor="isHtml(attribute)"
          :label="attribute | label"
          :language="language"
          v-model="properties[attribute.key]"
          @input="emit"
      />
      <form-input
          v-else-if="isInput(attribute)"
          :name="attribute.name"
          :label="attribute | label"
          :type="getInputType(attribute)"
          v-model="properties[attribute.key]"
          @input="emit"
      />
      <link-input
          v-else-if="isLink(attribute)"
          :label="attribute | label"
          :pages="pages"
          v-model="properties[attribute.key]"
          @input="emit"
      />
      <image-input
          v-else-if="isImage(attribute)"
          :label="attribute | label"
          v-model="properties[attribute.key]"
          :languages="languages"
          @input="emit"
      />
      <div v-else class="form-control h-auto">
        {{ attribute }}
      </div>
    </div>
  </div>
</template>

<script>
import LanguageInput from '../input/LanguageInput';
import PageAttributes from '../../mixins/PageAttributes';
import FormInput from '../input/FormInput';
import LinkInput from '../input/LinkInput';
import ImageInput from '../input/ImageInput';

export default {
    name: 'PageTypeAttributes',
    components: {ImageInput, LinkInput, FormInput, LanguageInput},
    mixins: [PageAttributes],
    props: {
        attributes: {type: Object, required: false, default: () => {}},
        pages: {type: Array, required: false, default: () => []},
        language: {type: String, default: null},
        languages: {type: Array, required: true},
        value: null,
    },
    data() {
        return {
            properties: {},
        };
    },
    methods: {
        applyBaseProperties() {
            let properties = {};

            if (this.value && !Array.isArray(this.value)) {
                properties = this.value;
            }

            for (let key of Object.keys(this.attributes)) {
                let attribute = this.attributes[key];
                if (properties.hasOwnProperty(attribute.key)) {
                    continue;
                }

                properties[attribute.key] = this.createDefault(attribute);
            }

            this.properties = properties;
        },
        emit() {
            this.$emit('input', this.properties);
        },
    },
    updated() {
        this.applyBaseProperties();
    },
    created() {
        this.applyBaseProperties();
    },
};
</script>

<style scoped>
.attribute-row:not(:last-child) {
    margin-bottom: 1rem;
}
</style>
