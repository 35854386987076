<template>
  <option
    :disabled="!nullable"
    :value="null"
    :selected="selected"
    v-text="$t('select.choose')"/>
</template>

<script>
export default {
    name: 'EmptyOption',
    props: {
        nullable: {type: Boolean, required: false, default: false},
        selected: {type: Boolean, required: false, default: false},
    },
};
</script>
