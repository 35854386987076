<template>
  <div>
    <modal title="Failed Job" ref="modal" submitClass="d-none" cancelClass="btn-outline-secondary rounded-0">
      <p class="overflow-auto">
                <span v-for="message in job.exception.split('\n')">
                    {{ message }}<br>
                </span>
      </p>
      <template slot="cancelLabel">
        Close
      </template>
    </modal>
    <div class="btn-group">
      <button
          type="button"
          class="btn btn-sm btn-outline-secondary"
          @click="show"
      >
        <i class="fas fa-eye"/>
      </button>
      <button
          type="button"
          class="btn btn-sm btn-outline-primary"
          @click="requeue"
      >
        <i class="fas fa-yin-yang" :class="{'spinner' : loading}"/>
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'JobButtons',
    props: {
        job: {type: Object, required: true},
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        show() {
            this.$refs.modal.open();
        },
        requeue() {
            this.loading = true;
            axios.post(`/admin/jobs/${this.job.id}/requeue`)
            .then(() => this.$emit('queued'))
            .finally(() => this.loading = false);
        },
    },
};
</script>
