<template>
  <model-editor
    url="/api/v1/job-board/companies"
    :title="$t('title')"
    :type="$t('type')"
    :fields="rules"
    :columns="[{key: 'name', label: $t('field.name')}]"
    :languages="languages"
    sort="name"
  />
</template>

<script>
import ModelEditor from '../model/ModelEditor';

export default {
    name: 'CompanyEditor',
    components: {ModelEditor},
    props: {
        languages: {type: Array, required: true},
    },
    computed: {
        rules() {
            return require('./rules/CompanyRules')
                .default
                .rules(this);
        },
    },
    i18n: {
        messages: {
            en: {
                title: 'Companies',
                type: 'Company',
                field: {
                    name: 'Name',
                },
            },
            nl: {
                title: 'Bedrijven',
                type: 'Bedrijf',
                field: {
                    name: 'Naam',
                },
            },
        },
    },
};
</script>
