<template>
  <div class="card">
    <div class="card-header align-items-center d-flex p-2 pl-3">
      <div class="flex-grow-0 header-icons">
        <slot name="icons"/>
      </div>

      <span class="flex-grow-1" v-text="title"/>

      <div class="flex-grow-0 header-buttons">
        <button
          class="btn btn-sm btn-outline-success"
          :title="$t('crud.create')"
          @click="openCreate">
          <icon icon="plus"/>
        </button>

        <slot name="buttons"/>
      </div>
    </div>

    <div>
      <create-model
        ref="create"
        :type="type"
        :url="url"
        :languages="languages"
        :fields="fields"
        @save="reload"
      />

      <update-model
        ref="update"
        :type="type"
        :update-url="itemUrl"
        :languages="languages"
        :fields="fields"
        @update="reload"
      />

      <delete-model
        ref="delete"
        :type="type"
        :delete-url="itemUrl"
        @delete="reload"
      />

      <slot name="modals"/>
    </div>

    <table v-if="items && items.length > 0" class="table table-striped table-hover mb-0">
      <thead>
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="`th-${index}`">
          <slot :name="`head_${column.key}`">
            <span v-text="column.label"/>
          </slot>
        </th>

        <th/>
      </tr>
      </thead>

      <tbody class="table-sm">
      <tr v-for="(item, index) in items" :key="`row-${index}`">
        <td
          v-for="(column, columnIndex) in columns"
          :key="`td-${index}-${columnIndex}`"
          class="align-middle">
          <slot :name="`data_${column.key}`" :item="item">
            <span v-text="item[column.key]"/>
          </slot>
        </td>

        <td class="text-right">
          <button
            class="btn btn-sm btn-outline-primary"
            :title="$t('crud.update')"
            @click="openUpdate(item)">
            <i class="fas fa-edit"/>
          </button>

          <button
            v-if="itemUrl"
            class="btn btn-sm btn-outline-danger ml-1"
            :title="$t('crud.delete')"
            @click="openDelete(item)">
            <i class="fas fa-trash-alt"/>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <div v-else class="card-body text-center text-black-50 font-italic">
      <template v-if="items === null && loading">
        {{ $t('table.loading', {type}) }}
      </template>

      <template v-else-if="items === null && !loading">
        {{ $t('table.error', {type}) }}
      </template>

      <template v-else>
        {{ $t('table.none', {type}) }}
      </template>
    </div>

    <div v-if="links" class="card-footer d-flex">
      <ul class="pagination m-auto">
        <li v-for="(link, index) in links"
            class="page-item"
            :class="{'active': link.active}"
            :key="`link-${index}`">
          <button
            class="page-link"
            type="button"
            :disabled="loading || !link.url"
            @click="getData(link.url)"
            v-html="link.label"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CreateModel from './CreateModel';
import UpdateModel from './UpdateModel';
import Icon from '../Icon';
import DeleteModel from "./DeleteModel";

export default {
    name: 'ModelEditor',
    components: {DeleteModel, CreateModel, UpdateModel, Icon},
    props: {
        title: {type: String, required: true},
        type: {type: String, required: true},
        url: {type: String, required: true},
        fields: {type: Object, required: true},
        columns: {type: Array, required: true},
        sort: {type: String, required: false, default: undefined},
        languages: {type: Array, required: true},
    },
    data: () => ({
        lastUrl: null,
        loading: false,
        items: null,
        links: null,
    }),
    created() {
        return this.getData();
    },
    methods: {
        getData(url = null) {
            if (this.loading) {
                return null;
            }

            this.loading = true;

            return axios.get(this.getDataUrl(url), this.getDataParams())
                .then(this.getDataSuccess)
                .catch(this.getDataFailed)
                .finally(this.getDataFinally);
        },
        getDataUrl(url) {
            return (this.lastUrl = url ?? this.lastUrl ?? this.url);
        },
        getDataParams() {
            const params = {};

            if (this.sort) {
                params.sort = this.sort;
            }

            return {params};
        },
        getDataSuccess({data: response}) {
            this.items = response.data;
            this.links = response.meta.links;
        },
        getDataFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.index', {type: this.type}),
                text: this.$errors.set([], error),
            });
        },
        getDataFinally() {
            this.loading = false;
        },
        reload() {
            return this.getData();
        },


        openCreate() {
            this.$refs.create.open();
        },
        openUpdate(item) {
            this.$refs.update.open(item);
        },
        openDelete(item) {
            this.$refs.delete.open(item);
        },
        itemUrl(item) {
            return `${this.url}/${item.id}`;
        },
    },
    i18n: {
        messages: require('./ModelMessages').default,
    },
};
</script>

<style scoped lang="scss">
table.table thead th {
  border-top: none;
  border-bottom: none;
}

.header-icons > * {
  margin-right: .5rem;
}

.header-buttons > * {
  margin-left: .5rem;
}
</style>
