<template>
  <div class="form-group row">
    <label class="col-sm-4 col-form-label text-md-right"
           :for="inputId"
           v-html="'Link: ' + label"/>
    <div class="col-md-6">
      <div class="input-group">
        <vue-select v-if="type === 'page'" :options="pages" v-model="page"/>

        <input v-else
               class="form-control"
               :class="{'is-invalid': error}"
               v-model="link">


        <select class="custom-select input-group-append select-type"
                v-model="type">
          <option value="link" v-html="'URL'"/>
          <option value="page" v-html="'Page'"/>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from './VueSelect';

const PAGE_REGEX = /p:(\d+|null)/g;

export default {
    name: 'LinkInput',
    components: {VueSelect},
    props: {
        pages: {type: Array, required: false, default: () => []},
        label: {type: String, required: true},
        error: {type: String, required: false},
        value: null,
    },
    data() {
        return {
            type: 'link',
            page: null,
            link: null,
        };
    },
    computed: {
        inputId: function () {
            return this._uid + '-in';
        },
    },
    watch: {
        type: function (type) {
            if (type !== 'page') {
                this.page = null;
                if (this.link && this.link.match(PAGE_REGEX)) {
                    this.link = null;
                }
            }

            this.emitValue();
        },
        page: function (page) {
            if (this.type !== 'page' || !page) {
                return;
            }

            this.emitValue();
        },
        link: function () {
            if (this.type !== 'link') {
                return;
            }

            this.emitValue();
        },
    },
    methods: {
        applyValue() {
            if (this.value && this.value.match(PAGE_REGEX)) {
                this.type = 'page';
                this.page = this.value.split(':')[1];
            } else {
                this.type = 'link';
                this.link = this.value;
            }
        },

        emitValue() {
            this.$emit('input', this.getValue());
        },

        getValue() {
            switch (this.type) {
                case 'link':
                    return this.link;
                default:
                    return `p:${this.page}`;
            }
        },
    },
    updated() {
        this.applyValue();
    },
    created() {
        this.applyValue();
    },
};
</script>

<style scoped>
.form-group.row:last-child {
    margin-bottom: 0 !important;
}

.select-type {
    max-width: max-content;
}
</style>
