export default {
    install(Vue) {
        Vue.prototype.$valueBuilder = {
            create(rules) {
                return {
                    rules: rules,
                    values: this.buildFields(rules, true),
                    errors: this.buildFields(rules),
                };
            },

            buildFields(rules, defaults = false) {
                return Object.keys(rules).reduce((map, key) => {
                    map[key] = defaults ? this.createDefault(rules[key]) : null;

                    return map;
                }, {});
            },
            createDefault(rule) {
                switch (rule.type) {
                    case 'translation':
                        return {};

                    default:
                        return null;
                }
            },

            extractData(values) {
                const self = this;
                const formData = new FormData();
                Object.keys(values).forEach((key) => {
                    if (!!values[key]) {
                        self.extractDataArray(key, values[key], formData);
                    }
                })

                return formData;
            },

            extractDataArray(key, value, formData) {
                const self = this;
                if(typeof value === 'object') {
                    Object.keys(value).forEach((arrayKey) => {
                        self.extractDataArray(`${key}[${arrayKey}]`, value[arrayKey], formData);
                    });
                    return formData;
                }

                formData.append(key, value);
                return formData;
            },

            applyItem(item, values, errors = null) {
                Object.keys(values).forEach((key) => {
                    values[key] = item[key] || null;
                });

                this.resetMap(errors);
            },

            reset(rules, values, errors, delay = false) {
                if (delay) {
                    setTimeout(() => this.resetValues(rules, values, errors), 500);
                } else {
                    this.resetValues(rules, values, errors);
                }
            },
            resetValues(rules, values, errors) {
                this.resetMap(rules, values, true);
                this.resetMap(rules, errors);
            },
            resetMap(rules, map, defaults = false) {
                if (!map) {
                    return;
                }

                Object.keys(map).forEach((key) => {
                    map[key] = defaults
                        ? this.createDefault(rules[key])
                        : null;
                });
            },
        };
    },
};
