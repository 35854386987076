<template>
  <div class="modal fade"
       tabindex="-1"
       aria-hidden="true"
       role="dialog"
       ref="modal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
         :class="modalClass"
         role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :class="titleClass" v-html="title"/>
          <button type="button" class="close" tabindex="-1" data-dismiss="modal"
                  @click="onCancel"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <slot/>
        </div>

        <div class="modal-footer justify-content-between p-2">
          <div>
            <slot name="footer"/>
          </div>

          <div>
            <template v-if="isWorking">
              <spinner>
                <template v-if="submitText">
                  {{ submitText }}
                </template>
              </spinner>
            </template>

            <template v-else>
              <button
                type="button"
                class="btn"
                :class="cancelClass"
                :disabled="isWorking"
                data-dismiss="modal"
                @click="onCancel">
                <slot name="cancelLabel">
                  {{ $t('cancel') }}
                </slot>
              </button>

              <slot name="buttons"/>

              <button
                type="button"
                class="btn"
                :class="submitClass"
                :disabled="isWorking"
                @click="onSubmit">
                <slot name="submitLabel">
                  <icon icon="save"/>
                  {{ $t('submit') }}
                </slot>
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon';

export default {
    name: 'Modal',
    components: {Icon},
    props: {
        title: {type: String, required: true},
        submitText: {type: String, required: false},
        modalClass: {type: String, required: false, default: 'modal-xl'},
        titleClass: {type: String, required: false, default: ''},
        cancelClass: {type: String, required: false, default: 'btn-secondary'},
        submitClass: {type: String, required: false, default: 'btn-primary'},
        isWorking: {type: Boolean, default: false},
    },
    methods: {
        onCancel() {
            this.$emit('cancel');
            this.close();
        },
        onSubmit() {
            this.$emit('submit');
        },
        open() {
            $(this.$refs.modal).modal('show');
        },
        close() {
            $(this.$refs.modal).modal('hide');
        },
    },
    i18n: {
        messages: {
            en: {
                cancel: 'Cancel',
                submit: 'Submit',
            },
            nl: {
                cancel: 'Annuleer',
                submit: 'Versturen',
            },
        },
    },
};
</script>
