var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('model-editor',{attrs:{"url":"/api/v1/job-board/vacancies","title":_vm.$t('title'),"type":_vm.$t('type'),"fields":_vm.rules,"columns":[
      {key: 'company_id', label: _vm.$t('field.company')},
      {key: 'location_id', label: _vm.$t('field.location')},
      {key: 'employment', label: _vm.$t('field.employment')},
      {key: 'field_of_study', label: _vm.$t('field.field')} ],"languages":_vm.languages},scopedSlots:_vm._u([{key:"data_company_id",fn:function(ref){
  var vacancy = ref.item;
return [_vm._v("\n    "+_vm._s(_vm.label(_vm.companies, vacancy.company_id))+"\n  ")]}},{key:"data_location_id",fn:function(ref){
  var vacancy = ref.item;
return [_vm._v("\n    "+_vm._s(_vm.label(_vm.locations, vacancy.location_id))+"\n  ")]}},{key:"data_employment",fn:function(ref){
  var vacancy = ref.item;
return [_vm._v("\n    "+_vm._s(_vm.label(_vm.employments, vacancy.employment))+"\n  ")]}},{key:"data_field_of_study",fn:function(ref){
  var vacancy = ref.item;
return [_vm._v("\n    "+_vm._s(_vm.label(_vm.fields, vacancy.field_of_study))+"\n  ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }