export default {
    rules: (wrapper) => ({
        translation_title: {label: wrapper.$t('field.title'), type: 'translation', required: true},
        translation_content: {label: wrapper.$t('field.content'), type: 'translation', html: true, required: true},
        company_id: {
            label: wrapper.$t('field.company'),
            type: 'select',
            options: wrapper.companies,
            required: true,
            enabled: () => !wrapper.companyId,
        },
        location_id: {
            label: wrapper.$t('field.location'),
            type: 'select',
            options: wrapper.locations,
            required: true,
        },
        employment: {
            label: wrapper.$t('field.employment'),
            type: 'select',
            options: wrapper.employments,
            required: true,
        },
        field_of_study: {
            label: wrapper.$t('field.field'),
            type: 'select',
            options: wrapper.fields,
            required: true,
        },
    }),
};
