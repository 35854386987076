export default {
    rules: (wrapper) => ({
        location: {
            label: wrapper.$t('form.location'),
            type: 'select',
            options: [
                {value: 'zwolle', title: 'Zwolle'},
                {value: 'almere', title: 'Almere'},
            ],
            required: true,
        },
    }),
};
