<template>
  <div class="card">
    <div class="card-header d-flex align-items-baseline">
      <span class="card-title flex-grow-1" v-html="hasSponsor ? 'Edit sponsor' : 'Add sponsor'"/>

      <a v-if="hasSponsor && page" :href="page" target="_blank" class="ml-2">
        <fa icon="external-link-square-alt"/>
        Show sponsor
      </a>
      <language-selector :languages="languages" v-model="language"/>
    </div>
    <div class="card-body">
      <form-input
        label="Name"
        v-model="attributes.name"
        :error="errors.name"
      />
      <form-input
        label="Slug"
        v-model="attributes.slug"
        :error="errors.slug"
      />

      <form-input
        label="Company"
        type="select"
        v-model="attributes.company_id"
        :error="errors.company_id"
        :options="companies"
        :nullable="true"
      />

      <image-input
        label="Image"
        v-model="attributes.image"
        :error="errors.image"
        :languages="languages"
      />

      <form-input
        label="Show dedicated page"
        type="checkbox"
        v-model="attributes.has_dedicated_page"
        :error="errors.has_dedicated_page"
      />

      <form-input
        v-if="!attributes.has_dedicated_page"
        label="Dedicated page"
        v-model="attributes.dedicated_link"
        :error="errors.dedicated_link"
        help="A link to direct instead of the dedicated page. (Not required)"
      />

      <hr/>

      <language-input
        label="Summary (Shown on the Sponsor list)"
        :language="language"
        :key="`summary-${language}`"
        :html-editor="true"
        :error="errors.summary_array"
        v-model="attributes.summary_array"
      />

      <language-input
        v-if="attributes.has_dedicated_page"
        label="Content (Shown on its own page)"
        :language="language"
        :key="`description-${language}`"
        :html-editor="true"
        :error="errors.description_array"
        v-model="attributes.description_array"
      />
    </div>
    <div class="card-footer text-right">
      <delete v-if="hasSponsor && urlDestroy"
              model="sponsor"
              :url="urlDestroy"/>

      <button class="btn btn-outline-primary btn-sm"
              type="button"
              :disabled="loading"
              @click="submit">
                <span v-if="loading">
                    <fa icon="circle-notch spinner"/>
                    Saving
                </span>
        <span v-else>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
import LanguageSelector from '../input/LanguageSelector';
import LanguageInput from '../input/LanguageInput';
import FormInput from '../input/FormInput';
import Delete from '../input/Delete';
import Fa from '../fa';
import ImageInput from '../input/ImageInput';

export default {
    name: 'Editor',
    components: {ImageInput, Fa, Delete, FormInput, LanguageInput, LanguageSelector},
    props: {
        languages: {type: Array, required: true},
        url: {type: String, required: true},
        urlDestroy: {type: String, required: false},
        page: {type: String, required: false},
        sponsor: {type: Object, required: false, default: null},
    },
    data() {
        return {
            companies: [],
            loadingCompanies: false,
            attributes: this.getAttributes(true),
            errors: this.getAttributes(),
            loading: false,
            language: null,
        };
    },
    computed: {
        hasSponsor: function () {
            return this.sponsor !== null;
        },
    },
    methods: {
        getAttributes(defaults = false) {
            return {
                image: null, // default is null
                name: defaults ? '' : null,
                slug: defaults ? '' : null,
                company_id: null, // default is null
                has_dedicated_page: defaults ? true : null,
                dedicated_link: defaults ? '' : null,
                summary_array: defaults ? [] : null,
                description_array: defaults ? [] : null,
            };
        },

        getCompanies() {
            if (this.loadingCompanies) {
                return null;
            }

            this.loadingCompanies = true;

            return axios.get('/api/v1/job-board/companies')
                .then(this.getCompaniesSuccess)
                .catch(this.getCompaniesFailed)
                .finally(this.getCompaniesFinally);
        },
        getCompaniesSuccess({data: response}) {
            this.companies = response.data.map((data)=> {
                return {
                    value: data.id,
                    text: data.name,
                }
            });
        },
        getCompaniesFailed(error) {
            this.$notify({
                type: 'error',
                title: this.$t('errors.companies'),
                text: this.$errors.set([], error),
            });
        },
        getCompaniesFinally() {
            this.loadingCompanies = false;
        },

        submit() {
            if (this.loading) return;

            return this.submitStart(this.url, this.attributes)
                .then(this.submitSuccess)
                .catch(this.submitError)
                .finally(this.submitFinally);
        },
        submitStart(url, data) {
            this.loading = true;
            return this.hasSponsor
                ? axios.put(url, data)
                : axios.post(url, data);
        },
        submitSuccess(response) {
            if (response.status === 201) {
                window.location = response.data.data.id;
                return;
            }

            this.attributes = response.data.data;
            this.$errors.clear(this.errors);

            this.$notify('Sponsor has been saved!');
        },
        submitError(error) {
            this.$notify({
                type: 'error',
                title: 'An error occurred while adding this sponsor',
                text: this.$errors.set(this.errors, error),
            });
        },
        submitFinally() {
            this.loading = false;
        },
    },
    created() {
        if (this.hasSponsor) {
            this.attributes = this.sponsor;
        }

        return this.getCompanies();
    },
    i18n: {
        messages: {
            en: {errors: {companies: 'Something went wrong while loading the Company data.'}},
            nl: {errors: {companies: 'Er ging iets mis bij het laden van de Company data.'}},
        },
    },
};
</script>
