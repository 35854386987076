<template>
  <i class="fas" :class="[`fa-${icon}`]"/>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        icon: {type: String, required: true},
    },
};
</script>
