<template>
  <div class="card">
    <div class="card-header d-flex align-items-baseline">
      <span class="card-title flex-grow-1" v-html="hasPage ? 'Edit page' : 'Create page'"/>

      <a v-if="hasPage" :href="page.uri" target="_blank" class="ml-2">
        <fa icon="external-link-square-alt"/>
        Show page
      </a>
      <language-selector :languages="languages" v-model="language"/>
    </div>
    <div class="card-body">
      <form-input
          label="Page slug"
          name="slug"
          v-model="attributes.slug"
          :error="errors.slug"
      />
      <form-input
          label="Page type"
          name="page_type_id"
          type="select"
          v-model="attributes.page_type_id"
          :error="errors.page_type_id"
          :options="pageTypes"
      />
      <form-input
          label="Visible"
          name="visible"
          type="checkbox"
          v-model="attributes.visible"
          :error="errors.visible"
      />

      <hr/>

      <language-input
          label="Title"
          :language="language"
          v-model="attributes.title_array"
          :error="errors.title_array"
      />
      <language-input
          label="Content"
          :language="language"
          :html-editor="true"
          v-model="attributes.content_array"
      />

      <page-type-attributes
          :attributes="pageTypeAttributes"
          :pages="pages"
          :language="language"
          :languages="languages"
          v-bind:key="attributes.page_type_id"
          v-model="attributes.type_attributes"
      />
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-outline-primary btn-sm"
              type="button"
              :disabled="loading"
              @click="submit">
                <span v-if="loading">
                    <fa icon="circle-notch spinner"/>
                    Saving
                </span>
        <span v-else>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
import PageTypeAttributes from './PageTypeAttributes';
import LanguageSelector from '../input/LanguageSelector';
import LanguageInput from '../input/LanguageInput';
import FormInput from '../input/FormInput';
import Fa from '../fa';

export default {
    name: 'Editor',
    components: {Fa, FormInput, LanguageInput, LanguageSelector, PageTypeAttributes},
    props: {
        pageTypes: {type: Array, required: true},
        languages: {type: Array, required: true},
        pages: {type: Array, required: false, default: () => []},

        url: {type: String, required: true},
        page: {type: Object, required: false, default: null},
    },
    data() {
        return {
            attributes: this.getAttributes(true),
            pageTypeAttributes: {},
            errors: this.getAttributes(),
            loading: false,
            language: null,
        };
    },
    watch: {
        'attributes.page_type_id': function () {
            this.updatePageTypeAttributes();
        },
    },
    computed: {
        hasPage: function () {
            return this.page !== null;
        },
    },
    methods: {
        getAttributes(defaults = false) {
            return {
                slug: null,
                page_type_id: null,
                visible: defaults ? true : null,
                title_array: defaults ? [] : null,
                content_array: defaults ? [] : null,
                type_attributes: null,
            };
        },
        updatePageTypeAttributes() {
            if (this.attributes.page_type_id !== null) {
                for (let pageType of this.pageTypes) {
                    if (
                        parseInt(pageType.value) !== // can be string
                        parseInt(this.attributes.page_type_id) // number is not int
                    ) {
                        continue;
                    }

                    if (pageType.hasOwnProperty('attributes')) {
                        this.pageTypeAttributes = pageType.attributes;
                        return;
                    }

                    break;
                }
            }

            this.pageTypeAttributes = {};
        },

        submit() {
            if (this.loading) {
                return;
            }

            this.submitStart(this.url, this.attributes)
            .then(this.submitSuccess)
            .catch(this.submitError)
            .finally(this.submitFinally);
        },
        submitStart(url, data) {
            this.loading = true;
            return this.hasPage
                ? axios.put(url, data)
                : axios.post(url, data);
        },
        submitSuccess(response) {
            if (response.status === 201) {
                window.location = response.data.data.id;
                return;
            }

            this.attributes = response.data.data;
            this.$errors.clear(this.errors);

            this.$notify('Page has been saved!');
        },
        submitError(error) {
            this.$notify({
                type: 'error',
                title: 'An error occurred while saving this page',
                text: this.$errors.set(this.errors, error),
            });
        },
        submitFinally() {
            this.loading = false;
        },
    },
    created() {
        if (this.hasPage) {
            this.attributes = this.page;
            this.updatePageTypeAttributes();
        }
    },
};
</script>
