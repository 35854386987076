<template>
  <div>
    <img :src="spinner" alt="spinner"/>
    <slot>Loading</slot>
  </div>
</template>

<script>
export default {
    name: 'Spinner',
    props: {
        green: {type: Boolean, required: false, default: false},
    },
    computed: {
        spinner() {
            return this.green
                ? '/images/spinner-green.svg'
                : '/images/spinner.svg';
        },
    },
};
</script>

<style lang="scss" scoped>
img {
  max-height: 20px;
}
</style>
