<script>
import Enrollment from './Enrollment';
import studentRules from './rules/student';

export default {
    name: 'EnrollStudent',
    extends: Enrollment,
    methods: {
        fieldRules: () => studentRules,
    },
    i18n: {
        messages: {
            en: {
                nr: 'Student number',
                card: 'College card',
                study: {
                    field: 'Field of study',
                    type: 'Study type',
                    minor: 'Minor name',
                },
                cohort: {
                    title: 'Cohort',
                    explain: 'The year that you started with the study',
                },
            },
            nl: {
                nr: 'Studentennummer',
                card: 'Collegekaart',
                study: {
                    field: 'Studierichting',
                    type: 'Studievorm',
                    minor: 'Naam minor',
                },
                cohort: {
                    title: 'Cohort',
                    explain: 'Het jaar dat je begonnen bent met de opleiding',
                },
            },
        },
        sharedMessages: require('./messages').default,
    },
};
</script>
