export default {
    name: 'HasFieldRules',
    props: {
        fields: {type: Object, required: true},
    },
    data: () => ({
        rules: {},
        values: {},
        errors: {},
    }),
    computed: {
        hasTranslations() {
            return Object.keys(this.rules).filter((rule) => this.rules[rule].type === 'translation').length > 0;
        },
    },
    watch: {
        fields: {
            deep: true,
            handler() {
                this.applyRules();
            },
        },
    },
    created() {
        this.applyRules();
    },
    methods: {
        applyRules() {
            const data = this.$valueBuilder.create(this.fields);

            this.rules = data.rules;
            this.values = data.values;
            this.errors = data.errors;
        },
        reset() {
            if ('item' in this) {
                setTimeout(() => (this.item = {}), 500);
            }

            this.$valueBuilder.reset(
                this.rules,
                this.values,
                this.errors,
                true,
            );
        },
    },
};
