<template>
  <div>
    <vue-input
      v-for="(rule, key) in rules"
      v-if="'enabled' in rule ? rule.enabled() : true"
      :key="key"
      :type="rule.type"
      :name="key"
      :label="rule.label"
      :help="rule.help"
      :error="errors[key]"
      :pattern="rule.pattern"
      :required="rule.required"
      :disabled="disabled"
      :options="rule.options"
      :html="rule.html"
      :language="language"
      v-model="values[key]"
      @submit="$emit('submit')"
    />
  </div>
</template>

<script>
import VueInput from '../VueInput';

export default {
    name: 'FieldsInput',
    components: {VueInput},
    props: {
        item: {type: Object, required: false},
        rules: {type: Object, required: true},
        values: {type: Object, required: true},
        errors: {type: Object, required: true},
        disabled: {type: Boolean, required: false, default: false},
        language: {type: String, required: false, default: 'nl'},
    },
    watch: {
        item: {
            deep: true,
            handler() {
                this.applyItem();
            },
        }
    },
    created() {
        this.applyItem();
    },
    methods: {
        applyItem() {
            if (!this.item || Object.keys(this.item).length === 0) {
                this.$valueBuilder.reset(this.rules, this.values, this.errors);

                return;
            }

            this.$valueBuilder.applyItem(this.item, this.values, this.errors);
        },
    },
}
</script>
