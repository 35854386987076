<template>
  <div>
    <div class="card-body">
      <template v-if="message">
        <div class="alert alert-success mb-0" v-text="message"/>
      </template>

      <template v-else>
        <fields-input
          :disabled="working"
          :rules="rules"
          :values="values"
          :errors="errors"
        />
      </template>
    </div>

    <div v-if="!message" class="card-footer text-right">
      <button type="button"
              class="btn btn-outline-call-to-action"
              :disabled="working"
              @click="submit">
        <span v-if="!working" v-text="$t('form.send')"/>
        <spinner v-else/>
      </button>
    </div>
  </div>
</template>

<script>
import FieldsInput from '../input/FieldsInput';

export default {
    name: 'Enrollment',
    components: {FieldsInput},
    props: {
        url: {type: String, required: true},
    },
    data: () => ({
        rules: {},
        values: {},
        errors: {},
        message: null,
        working: false,
    }),
    created() {
        const rules = this.fieldRules().rules(this);
        const data = this.$valueBuilder.create(rules);

        this.rules = data.rules;
        this.values = data.values;
        this.errors = data.errors;
    },
    methods: {
        submit() {
            if (this.working) {
                return null;
            }

            this.working = true;

            return axios.post(this.url, this.submitData(), {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(this.submitSuccess)
                .catch(this.submitError)
                .finally(this.submitFinally);
        },
        submitData() {
            return this.$valueBuilder.extractData(this.values);
        },
        submitSuccess({data: response}) {
            this.$errors.clear(this.errors);
            this.message = response.data.message;
        },
        submitError(error) {
            this.$errors.set(this.errors, error);
        },
        submitFinally() {
            this.working = false;
        },
    },
};
</script>
