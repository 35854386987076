<template>
  <select class="custom-select" v-on:input="$emit('input', $event.target.value)">
    <option v-for="option in languages" v-bind:value="option.value">
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
    name: 'LanguageSelector',
    props: {
        append: {type: Boolean, default: false},
        languages: {type: Array, required: true, default: () => []},
        value: null,
    },
    created() {
        if ((this.value !== null && this.value.length > 0) || this.languages.length === 0) {
            return;
        }

        this.$emit('input', this.languages[0].value);
    },
};
</script>

<style scoped lang="scss">
select {
  max-width: max-content;
  min-width: max-content;
  width: max-content;
}
</style>
