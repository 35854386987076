<template>
  <div :style="`background-position: 50% calc(50% + ${offset}px);`">
    <slot/>
  </div>
</template>

<script>
export default {
    name: 'Parallax',
    props: {
        speed: {type: Number, default: 5},
    },
    data: () => ({
        offset: 0,
    }),
    methods: {
        getTop() {
            let doc = document.documentElement;
            return (window.pageYOffset || doc.scrollTop)
                - (doc.clientTop || 0);
        },

        onScroll() {
            this.offset = this.getTop() / this.speed;
        },
    },
    created() {
        $(window).scroll(this.onScroll);
    },
};
</script>
