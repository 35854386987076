<template>
  <div class="card">
    <div class="card-header d-flex align-items-baseline">
      <span class="card-title flex-grow-1" v-html="hasImage ? 'Edit image' : 'Upload image'"/>

      <language-selector :languages="languages" v-model="language"/>
    </div>
    <div class="card-body">
      <language-input
          label="Name"
          :language="language"
          :error="errors.alt_array"
          v-model="attributes.alt_array"
      />

      <form-input
          label="Image"
          type="file"
          accept="image/png"
          :error="errors.file"
          v-model="attributes.file"
      />
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-outline-primary btn-sm"
              type="button"
              :disabled="loading"
              @click="submit">
                <span v-if="loading">
                    <fa icon="circle-notch spinner"/>
                    Uploading
                </span>
        <span v-else>Upload</span>
      </button>
    </div>
  </div>
</template>

<script>
import LanguageSelector from '../input/LanguageSelector';
import LanguageInput from '../input/LanguageInput';
import FormInput from '../input/FormInput';
import Fa from '../fa';

export default {
    name: 'ImageEditor',
    components: {Fa, FormInput, LanguageInput, LanguageSelector},
    props: {
        languages: {type: Array, required: true},
        url: {type: String, required: true},
        urlDestroy: {type: String, required: false},
        image: {type: Object, required: false, default: null},
    },
    data() {
        return {
            attributes: this.getAttributes(true),
            errors: this.getAttributes(),
            loading: false,
            language: null,
        };
    },
    computed: {
        hasImage: function () {
            return this.image !== null;
        },
    },
    methods: {
        getAttributes(defaults = false) {
            return {
                alt_array: defaults ? [] : null,
                file: defaults ? '' : null,
            };
        },
        convertData() {
            let data = new FormData;
            data.append('file', this.attributes.file);

            for (let language of this.languages) {
                if (!this.attributes.alt_array.hasOwnProperty(language.value)) {
                    continue;
                }

                data.append(
                    `alt_array[${language.value}]`,
                    this.attributes.alt_array[language.value],
                );
            }

            return data;
        },

        submit() {
            if (this.loading) {
                return;
            }

            this.submitStart(this.url, this.convertData())
            .then(this.submitSuccess)
            .catch(this.submitError)
            .finally(this.submitFinally);
        },
        submitStart(url, data) {
            this.loading = true;
            return this.hasImage
                ? axios.put(url, data)
                : axios.post(url, data);
        },
        submitSuccess(response) {
            window.location = '..';
        },
        submitError(error) {
            this.$notify({
                type: 'error',
                title: 'An error occurred while uploading this image',
                text: this.$errors.set(this.errors, error),
            });
        },
        submitFinally() {
            this.loading = false;
        },
    },
    created() {
        if (this.hasImage) {
            this.attributes = this.image;
        }
    },
};
</script>
