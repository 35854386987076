<template>
  <div class="form-group row">
    <div v-if="type === 'checkbox'" class="col-sm-4"/>

    <label v-else
           :for="inputId"
           class="col-sm-4 col-form-label text-md-right"
           v-html="label"/>

    <div class="col-md-6">
      <select v-if="type === 'select'"
              class="custom-select"
              :class="{'is-invalid' : error}"
              :id="inputId"
              :required="required"

              :value="value"
              @input="emitValue">
        <empty-option :nullable="nullable"/>

        <option v-for="option in options"
                :value="option.value"
                v-text="option.text"/>
      </select>

      <div v-else-if="type === 'checkbox'"
           class="custom-control custom-checkbox">
        <input class="custom-control-input"
               :class="{'is-invalid' : error}"
               :id="inputId"
               :name="name"
               :type="type"
               :reaquired="required"

               v-bind:checked="value === true ? 'checked' : null"
               v-on:input="emitValue"/>
        <label class="custom-control-label" :for="inputId" v-html="label"/>

        <form-labels
          :error="error"
          :help="help"
        />
      </div>
      <div v-else-if="type === 'file'">
        <div class="custom-file">
          <input type="file"
                 class="custom-file-input"
                 :class="{'is-invalid' : error}"
                 :id="inputId"
                 :name="name"
                 :accept="accept"
                 v-on:input="emitValue">
          <label class="custom-file-label" :for="inputId">
            <span v-if="value" v-html="value.name"/>
            <span v-else>Select file</span>
          </label>
        </div>
      </div>
      <input v-else
             class="form-control"
             :class="{'is-invalid' : error}"
             :id="inputId"
             :name="name"
             :type="type"
             :step="step"
             :placeholder="placeholder"
             :required="required"

             v-bind:value="value"
             v-on:input="emitValue"/>

      <form-labels
        v-if="type !== 'checkbox'"
        :error="error"
        :help="help"
        class="d-block"
      />
    </div>
  </div>
</template>

<script>
import FormLabels from './FormLabels';
import EmptyOption from './EmptyOption';

export default {
    name: 'FormInput',
    components: {EmptyOption, FormLabels},
    props: {
        name: {type: String, required: false},
        type: {type: String, default: 'text'},
        step: {type: String, required: false},
        accept: {type: String, required: false},
        required: {type: Boolean, default: false},
        label: {type: String, required: true},
        placeholder: {type: String, required: false},
        nullable: {type: Boolean, required: false, default: false},
        error: {type: String, required: false},
        help: {type: String, required: false},
        value: null,
        options: {type: Array, default: () => []},
    },
    computed: {
        inputId() {
            return `${this._uid}-input`;
        },
    },
    methods: {
        emitValue(event) {
            this.$emit('input', this.getValue(event));
        },

        getValue(event) {
            switch (this.type) {
                case 'checkbox':
                    return event.target.checked;
                case 'file':
                    return event.target.files[0];
                default:
                    return event.target.value;
            }
        },
    },
};
</script>

<style scoped>
.form-group.row:last-child {
  margin-bottom: 0 !important;
}
</style>
