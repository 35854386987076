export default {
    en: {
        select: {choose: 'Choose...'},
        study: {
            field: {
                p: 'Propedeuse',
                esa: 'Embedded systems and Automation',
                se: 'Software Engineering',
                bim: 'Business IT and Management',
                ids: 'Infrastructure Design and Security',
            },
            type: {
                full: 'full-time',
                part: 'part-time',
                minor: 'minor',
            },
        },
        form: {
            first_name: 'First name',
            infix_name: 'Infix',
            last_name: 'Last name',
            email: 'Email',
            send: 'Send',
        },
    },
    nl: {
        select: {choose: 'Kies...'},
        study: {
            field: {
                p: 'Propedeuse',
                esa: 'Embedded systems and Automation',
                se: 'Software Engineering',
                bim: 'Business IT and Management',
                ids: 'Infrastructure Design and Security',
            },
            type: {
                full: 'Voltijd',
                part: 'Deeltijd',
                minor: 'Minor',
            },
        },
        form: {
            first_name: 'Voornaam',
            infix_name: 'Tussenvoegsel',
            last_name: 'Achternaam',
            email: 'Email',
            send: 'Versturen',
        },
    },
};
