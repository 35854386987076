const {rules: nameRules} = require('./name').default;

export default {
    rules: (wrapper) => ({
        ...nameRules(wrapper),
        company: {label: wrapper.$t('company')},
        phone: {label: wrapper.$t('phone')},
        message: {label: wrapper.$t('message'), type: 'textarea', required: true},
    }),
};
