export default {
    en: {
        date: 'Date of birth',
        form: {
            location: 'Location',
        },
    },
    nl: {
        date: 'Geboortedatum',
        form: {
            location: 'Locatie',
        },
    },
};
