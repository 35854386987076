<template>
  <div>
    <div v-if="error" class="invalid-feedback d-block" v-text="error"/>
    <small v-if="help" class="form-text text-muted" v-text="help"/>
  </div>
</template>

<script>
export default {
    name: 'FormLabels',
    props: {
        error: {type: String, required: false},
        help: {type: String, required: false},
    },
};
</script>
