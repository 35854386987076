<template>
  <li class="nav-item">
    <button class="nav-link btn btn-link"
            @click="submit">
      <span>Update</span>

      <fa v-if="loading"
          icon="circle-notch spinner"/>
    </button>
  </li>
</template>

<script>
import Fa from '../fa';

export default {
    name: 'UpdateTypes',
    components: {Fa},
    props: {
        url: {type: String, required: true},
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        submit() {
            this.submitStart()
            .then(this.submitSuccess)
            .catch(this.submitError)
            .finally(this.submitFinally);
        },
        submitStart() {
            this.loading = true;
            return axios.post(this.url);
        },
        submitSuccess() {
            window.location.reload();
        },
        submitError(error) {
            this.$notify({
                type: 'error',
                text: this.$errors.set([], error),
            });
        },
        submitFinally() {
            this.loading = false;
        },
    },
};
</script>

<style scoped>
.btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
}

.fas {
    line-height: unset;
}
</style>
