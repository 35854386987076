export default {
    methods: {
        splitType(attribute) {
            return attribute.type.split('|');
        },

        createDefault(attribute) {
            if (this.isTranslation(attribute)) {
                return {};
            } else if (this.isImage(attribute)) {
                return null;
            } else if (this.hasDefault(attribute)) {
                return this.getDefault(attribute);
            }

            return '';
        },

        isLink(attribute) {
            return this.splitType(attribute)[0] === 'link';
        },

        isTranslation(attribute) {
            return this.splitType(attribute)
            .includes('translation');
        },

        isImage(attribute) {
            return this.splitType(attribute)[0] === 'image';
        },

        isHtml(attribute) {
            return this.splitType(attribute)
            .includes('html');
        },

        isInput(attribute) {
            return this.splitType(attribute)
            .includes('input');
        },

        getInputType(attribute) {
            if (!this.isInput(attribute)) {
                return 'text';
            }

            return this.splitType(attribute)[1];
        },

        hasDefault(attribute) {
            return attribute.hasOwnProperty('default');
        },

        getDefault(attribute) {
            return this.hasDefault(attribute)
                ? attribute['default']
                : null;
        },
    },
    filters: {
        label: function (attribute) {
            let value = attribute.name;
            if (!value) {
                return '';
            }

            return value.split('_')
            .map(x => x.charAt(0).toUpperCase() + x.slice(1))
            .join(' ');
        },
    },
};
